<template>
  <shipblu-prompt
    class="order-modal stock-request"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="orderModal"
    :title="type.includes('Product') ? $t(type) : $t('Add') + ' ' + $t(type === 'outbound' ? 'Outbound Order' : 'Inbound Order')"
    :buttons-hidden="true" autocomplete="nofill">
    <!-- PRODUCTS -->
    <div :class="index === 0 ? 'mt-8' : ''" v-for="(item, index) in products" :key="index">
      <vx-card class="mt-4 relative">
        <div class="grid w-full md:grid-cols-5 sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-2">
          <div class="col-span-1">
            <v-select :class="item.fulfillment_center ? 'input-customer-info-filled remove-search' : ''" class="w-full input-customer-info"
              autocomplete="nofill" label="name" :name="`fulfillment center-${index}`" v-model="item.fulfillment_center" v-validate="'required'"
              :placeholder="$t('Fulfillment Center') + '*'" :options="fulfillmentCenters"/>
            <span class="text-danger text-sm" v-show="errors.has(`fulfillment center-${index}`)">{{ errors.first(`fulfillment center-${index}`) ? errors.first(`fulfillment center-${index}`).split(`-${index}`)[0] + errors.first(`fulfillment center-${index}`).split(`-${index}`)[1]  : '' }}</span>
          </div>
          <div class="md:col-span-2 col-span-1">
            <shipblu-v-select :class="item.product ? 'input-customer-info-filled remove-search' : ''" class="w-full input-customer-info product-dropdown" 
              autocomplete="nofill" optionalLabel="optionalLabel" label="sku" :name="`product-${index}`" v-model="item.product" v-validate="'required'" 
              :placeholder="$t('Product') + '*'" :options="productCatalog" @search="handleProductSearch" @keyup.enter.native="handleProductSearch" search/>
            <span class="text-danger text-sm" v-show="errors.has(`product-${index}`)">{{ errors.first(`product-${index}`) ? errors.first(`product-${index}`).split(`-${index}`)[0] + errors.first(`product-${index}`).split(`-${index}`)[1]  : '' }}</span>
          </div>
          <div class="sm:flex sm:col-span-2 col-span-1">
            <div class="w-full">
              <v-select :class="item.flags.length !== 0 ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" 
                multiple autocomplete="nofill" label="name" :name="`flags-${index}`" v-model="item.flags" :placeholder="$t('Flags')" :options="productFlags"/>
            </div> 
            <div class="sm:ml-4 sm:mt-0 mt-4">
              <div class="sm:block flex items-center">
                <p class="text-sm text-lightgray">{{ $t('Quantity') }}:</p>
                <div>
                  <vs-input-number v-if="type === 'outbound' || $route.params.type === 'outbound'" min="1" :max="item.product ? item.product.current_inventory_level : 1" v-validate="'required|numeric'" :name="`quantity-${index}`" v-model="item.quantity" :value="0" class="stock-request-input-number flex justify-start"/>
                  <vs-input-number v-else min="1" v-validate="'required|numeric'" :name="`quantity-${index}`" v-model="item.quantity" :value="0" class="stock-request-input-number flex justify-start"/>
                  <span class="text-danger text-sm" v-show="errors.has(`quantity-${index}`)">{{ errors.first(`quantity-${index}`) ? errors.first(`quantity-${index}`).split(`-${index}`)[0] + errors.first(`quantity-${index}`).split(`-${index}`)[1]  : ''}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="products.length > 1" @click="removeProduct(index)" class="remove-package-icon cursor-pointer remove-icon-position">
          <feather-icon icon="XIcon" svgClasses="w-3 h-3"/>
        </div>
      </vx-card>
    </div>
    <div v-if="type !== 'Edit Product'" class="relative flex items-center justify-end mt-8">
      <div class="absolute add-products">
        <span class="text-sm font-medium py-1 px-2 rounded tooltip bg-white text-primary">{{$t('Add more products')}}</span>
      </div>
      <div class="p-1 flex self-center bg-primary rounded">
        <feather-icon @click="addProduct()" class="cursor-pointer" icon="PlusIcon" svgClasses="w-5 h-5 text-white" />
      </div>
    </div>
    <template v-if="!type.includes('Product')">
      <!-- ORDER -->
      <template v-if="order.shipping_provider_metadata === 'other'">
        <div class="grid grid-cols-2 sm:gap-4 gap-2">
          <div class="col-span-1">
            <vs-input :class="order.merchant_reference ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" name="merchant reference" :label-placeholder="$t('Merchant Reference') + '*'" v-model="order.merchant_reference"/>
          </div>
          <div class="col-span-1 self-end">
            <v-select :class="order.flags.length !== 0 ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"
              multiple autocomplete="nofill" label="name" :name="`flags`" v-model="order.flags" :placeholder="$t('Flags')" :options="productFlags"/>
          </div>
        </div>
        <div class="grid grid-cols-1 mt-2">
          <div class="col-span-1">
            <vs-input :class="order.notes ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" name="notes" :label-placeholder="$t('Notes') + '*'" v-model="order.notes"/>
          </div>
        </div>
      </template>
      <div class="mt-4" v-else>
        <v-select :class="order.flags.length !== 0 ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" 
          multiple autocomplete="nofill" label="name" :name="`flags`" v-model="order.flags" :placeholder="$t('Flags')" :options="productFlags"/>
      </div>
      <!-- Shipping Info -->
      <div class="sm:flex justify-between mt-6">
        <div class="flex mt-6 items-center sm:justify-start justify-center">
          <feather-icon icon="MinusIcon" svgClasses="w-4 mr-2 text-primary" />
          <p class="text-lg text-darkgray font-medium">{{$t('Shipping Info')}}</p>
        </div>
        <div class="flex items-center justify-center sm:mt-0 mt-2">
          <div v-for="(provider, index) in providers" :key="index">
            <label @click="checkedItem(provider, providers)" class="prompt cursor-pointer" :class="index === 0 ? 'mr-4' : ''">
              <input v-model="order.shipping_provider_metadata" :class="provider.checked ? 'checked' : ''" :value="provider.name" type="radio" />
              <span class="py-2 px-4 rounded capitalize text-sm">{{ $t(provider.name.replace('b', 'B')) }}</span>
            </label>
          </div>
        </div>
      </div>
      <!-- CUSTOMER -->
      <div class="items-end grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-2">
        <div class="col-span-1">
          <vs-input :class="order.customer.full_name ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" v-validate="'required'" :label-placeholder="$t('Full Name') + '*'" name="full name" v-model="order.customer.full_name"/>
          <span class="text-danger text-sm" v-show="errors.has('full name')">{{ errors.first('full name') }}</span>
        </div>
        <div class="col-span-1">
          <vs-input :class="order.customer.phone ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" v-validate="{ required: true, regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }" :label-placeholder="$t('Phone Number') + '*'" name="phone number" v-model="order.customer.phone"/>
          <span class="text-danger text-sm" v-show="errors.has('phone number')">{{ errors.first('phone number') }}</span>
        </div>
      </div>
      <div class="grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-2">
        <div v-if="!showAdditional" class="relative flex items-center justify-start mt-6">
          <div @click="showAdditional = true" class="p-1 flex self-center bg-primary rounded cursor-pointer">
            <feather-icon icon="PlusIcon" svgClasses="w-4 h-4 text-white" />
          </div>
          <div class="add-products">
            <span class="text-sm font-medium py-1 px-2 rounded text-lightgray">{{$t('Additional Phone Number')}}</span>
          </div>
        </div>
        <div class="col-span-2 mt-2" v-if="showAdditional">
          <vs-input name="additional phone number" v-validate="{ regex: /(^0[0-9]\d{8,15}$|^\u0660\u0661[\u0660-\u0669][\u0660-\u0669]{8,15}$)/ }" :class="order.customer.secondary_phone ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" :label-placeholder="$t('Additional Phone Number')" v-model="order.customer.secondary_phone"/>
          <span class="text-danger text-sm" v-show="errors.has('additional phone number')">{{ errors.first('additional phone number') }}</span>
        </div>
      </div>
      <div class="grid grid-cols-1">
        <div class="col-span-1 mt-2">
          <vs-input :class="order.customer.address.line_1 ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" v-validate="'required'" name="address 1" :label-placeholder="$t('Customer Address 1') + '*'" v-model="order.customer.address.line_1"/>
          <span class="text-danger text-sm" v-show="errors.has('address 1')">{{ errors.first('address 1') }}</span>
        </div>
        <div class="col-span-1 mt-2">
          <vs-input :class="order.customer.address.line_2 ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" v-validate="'required'" name="address 2" :label-placeholder="$t('Customer Address 2') + '*'" v-model="order.customer.address.line_2"/>
          <span class="text-danger text-sm" v-show="errors.has('address 2')">{{ errors.first('address 2') }}</span>
        </div>
      </div>
      <!-- PROVIDER -->
      <div class="grid grid-cols-1 gap-4 mt-4" v-if="order.shipping_provider_metadata === 'shipblu'">
        <div class="col-span-1">
          <v-select :class="order.customer_governorate ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" autocomplete="nofill" name="governorate" v-model="order.customer_governorate" v-validate="'required'" :placeholder="$t('Governorate') + '*'" label="name" :options="governorates"/>
          <span class="text-danger text-sm" v-show="errors.has('governorate')">{{ errors.first('governorate') }}</span>
        </div>
        <div class="col-span-1">
          <v-select :class="order.customer_city ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" autocomplete="nofill" name="city" v-model="order.customer_city"
            v-validate="'required'" :placeholder="$t('City') + '*'" label="name" :options="cities"/>
          <span class="text-danger text-sm" v-show="errors.has('city')">{{ errors.first('city') }}</span>
        </div>
        <div class="col-span-1">
          <v-select :class="order.customerZone ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" autocomplete="nofill" v-model="order.customerZone" v-validate="'required'"
            name="zone" :placeholder="$t('Zone') + '*'" label="name" :options="zones"/>
          <span class="text-danger text-sm" v-show="errors.has('zone')">{{ errors.first('zone') }}</span>
        </div>
        <div class="sm:flex items-center justify-between">
          <div class="flex items-center sm:justify-start justify-center">
            <img src="@/assets/images/logo/w3w.svg" alt="">
            <p class="leading-tight text-darkgray font-medium mx-1 text-xs text-lightgray">{{ $t('(Optional)') }}</p>
            <a target="blank" href="https://what3words.com/tricycle.hotel.jeering" class="material-icons-outlined text-primary text-2xl cursor-pointer">help_center</a>
          </div>
          <div class="sm:m-0 m-auto sm:mt-0 mt-3">
            <what3words-autosuggest clip_to_country="EG" :api_key="W3W_API_KEY">
              <input class="w-68 input-on" v-model="order.customer.address.what3words" name="w3w" id="what3words"/>
            </what3words-autosuggest>
          </div>
        </div>
        <div class="sm:flex justify-between mt-6">
          <div class="flex items-center justify-center">
            <feather-icon icon="MinusIcon" svgClasses="w-4 mr-2 text-primary" />
            <p class="leading-tight text-darkgray font-medium">{{ $t('The parcel') }}</p>
          </div>
          <div class="flex items-center justify-center sm:mt-0 mt-2">
            <div v-for="(parcel, index) in parcels" :key="index">
              <label @click="checkedItem(parcel, parcels)" class="prompt cursor-pointer" :class="index === 0 ? 'mr-4' : ''">
                <input v-model="order.is_customer_allowed_to_open_packages" :class="parcel.checked ? 'checked' : ''" :value="parcel.checked" type="checkbox" />
                <span class="py-2 px-4 rounded text-sm">{{ $t(parcel.name) }}</span>
              </label>
            </div>
          </div>
        </div>        
        <div class="sm:flex justify-between mt-6">
          <div class="flex items-center justify-center">
            <feather-icon icon="MinusIcon" svgClasses="w-4 mr-2 text-primary" />
            <p class="leading-tight text-darkgray font-medium">{{ $t('Service Level') }}</p>
          </div>
          <div class="flex items-center justify-center sm:mt-0 mt-2">
            <div v-for="(serviceLevel, index) in serviceLevels" :key="index">
              <label @click="checkedItem(serviceLevel, serviceLevels)" class="prompt cursor-pointer" :class="index === 0 ? 'mr-4' : ''">
                <input v-model="order.serviceLevel" :class="order.serviceLevel === serviceLevel ? 'checked' : ''" :value="serviceLevel" type="radio"/>
                <span class="py-2 px-4 rounded text-sm">{{ serviceLevel.name }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="sm:flex items-start justify-between mt-6">
          <div>
            <div class="flex items-center sm:justify-start justify-center">
              <feather-icon icon="MinusIcon" svgClasses="w-4 mr-2 text-primary" />
              <p class="leading-tight text-darkgray font-medium">{{ $t('Cash On Delivery (COD)') }}</p>
            </div>
            <div class="flex sm:items-center sm:text-left text-center m-auto sm:w-76 sm:px-2">
              <li class="w-2" style="color:#FF7556;"></li>
              <span class="text-sm text-icon ml-2">{{$t('Please, do not forget to add any applicable fees')}} {{$t('(Delivery, COD) to COD Amount!')}}</span>
            </div>
          </div>
          <div class="sm:m-0 sm:text-left text-center sm:mt-0 mt-2">
            <input class="w-68 text-right input-on" type="text" :placeholder="'0.00 ' + $t('EGP')" name="amount" v-model.number="codDisplay">
          </div>
        </div>
        <div class="sm:flex items-start justify-between mt-6" v-if="type === 'outbound' && (!parcels[1].checked && financialsInsurance.insurance_package.id !== 1 && financialsInsurance.insurance_package.id)">
          <div>
            <div class="flex items-center sm:justify-start justify-center">
              <feather-icon icon="MinusIcon" svgClasses="w-4 mr-2 text-primary" />
              <p class="leading-tight text-darkgray font-medium">{{ $t('Products declared value') }}</p>
            </div>
            <div class="flex sm:items-center sm:text-left text-center m-auto sm:w-76 sm:px-2">
              <li class="w-2" style="color:#FF7556;"></li>
              <span class="text-sm text-icon ml-2">{{$t('Insurance fees will be applied on this amount in case the COD amount = 0')}}</span><br>
            </div>
            <div class="flex sm:items-center sm:text-left text-center m-auto sm:px-2">
              <span class="text-sm text-icon ml-2">({{$t('This amount will not be shown to your customer')}})</span>
            </div>
          </div>
          <div class="sm:m-0 sm:text-left text-center sm:mt-0 mt-2">
            <input class="w-68 text-right input-on" type="text" :placeholder="'0.00 ' + $t('EGP')" name="amount" v-model.number="declaredValue" style="background: #F1F3F6 !important;">
          </div>
        </div>
        <div class="sm:flex items-center justify-between mt-6">
          <div class="flex items-center sm:justify-start justify-center">
            <feather-icon icon="MinusIcon" svgClasses="w-4 mr-2 text-primary" />
            <p class="leading-tight text-darkgray font-medium">{{ $t('Reference Number') }}</p>
          </div>
          <div class="sm:m-0 sm:text-left text-center sm:mt-0 mt-2">
            <input autocomplete="nofill" class="w-68 input-on" type="text" v-validate="'max:30'" :placeholder="$t('Merchant Order Reference No.')" name="reference" v-model="order.merchant_order_reference">
            <p class="text-danger text-sm" v-show="errors.has('reference')">{{ errors.first('reference') }}</p>
          </div>
        </div>
        <div class="mt-6">
          <div class="flex items-center sm:justify-start justify-center">
            <feather-icon icon="MinusIcon" svgClasses="w-4 mr-2 text-primary" />
            <p class="leading-tight text-darkgray font-medium">{{ $t('Do you want to leave notes?') }}</p>
          </div>
          <textarea class="resize-none w-full mt-3 notes" :placeholder="$t('your notes..')" rows="5" v-model="order.order_notes"/>
          <div class="flex justify-end">
            <li class="w-2" style="color:#FF7556;"></li>
            <span class="text-sm text-icon ml-2">{{ $t('"Notes" will be printed on the order\'s label.') }}</span>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 mt-4" v-else>
        <div class="col-span-1">
          <vs-input :class="order.customer_governorate ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" v-validate="'required'" :label-placeholder="$t('Governorate') + '*'" name="governorate" v-model="order.customer_governorate"/>
          <span class="text-danger text-sm" v-show="errors.has('governorate')">{{ errors.first('governorate') }}</span>
        </div>
        <div class="col-span-1">
          <vs-input :class="order.customer_city ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" v-validate="'required'" :label-placeholder="$t('City') + '*'" name="city" v-model="order.customer_city"/>
          <span class="text-danger text-sm" v-show="errors.has('city')">{{ errors.first('city') }}</span>
        </div>
      </div>
    </template>
    
    <div class="grid grid-cols-2 gap-4 mt-10">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button v-if="!type.includes('Product')"  @click="addOrder" class="active-btn btn">{{ $t('Add') }}</button>
      <button v-else-if="type === 'Add New Product'" @click="addOrderProduct" class="active-btn btn">{{ $t('Save') }}</button>
      <button v-else @click="updateProduct" class="active-btn btn">{{ $t('Save') }}</button>
    </div>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '../../../../layouts/components/ShipBluPrompt.vue'
import {sendRequest, sendFulfillmentRequest} from '../../../../http/axios/requestHelper.js'
import i18nData from '../../../../i18n/i18nData.js'
import vSelect from 'vue-select'
import common from '../../../../assets/utils/common'
import ShipbluVSelect from 'shipblu-vue-select'

export default {
  props: ['orderModal', 'type', 'order', 'orderProduct', 'orderProducts'],
  data () {
    return {
      financialsInsurance:{
        insurance_package: {}
      },
      showAdditional: false,
      W3W_API_KEY: process.env.VUE_APP_WHAT3WORDS_API_KEY,
      products: [
        {
          fulfillment_center: '',
          product: '',
          flags: [],
          quantity: 1
        }
      ],
      serviceLevels: [],
      subscriptionId:'',
      merchantID: '',
      allProductsList: [],
      productCatalog: [],
      productFlags: [],
      fulfillmentCenters: [],
      providers: [
        {
          name: 'shipblu',
          checked: true
        },
        {
          name: 'other',
          checked: false
        }
      ],
      governorates: [],
      cities: [],
      zones: [],
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      parcels: [
        {
          name: 'Can\'t Open',
          checked: true
        },
        {
          name: 'Can Open',
          checked: false
        }
      ]
    }
  },
  watch: {
    'order.shipping_provider_metadata' (val) {
      if (val === 'other') {
        this.order.customer_governorate = ''
        this.order.customer_city = ''
      }
    },
    'order.customer_governorate.id' (val) {
      if (this.order.customer_governorate) {
        this.loadServicesAvailability(val)
        this.loadCities(val)
      }
    },
    'products': {
      handler (val) {
        const choosedProducts = []
        if (this.orderProducts && this.orderProducts.length > 0) {
          this.orderProducts.forEach(item => {
            choosedProducts.push(item.product)
          })
        }
        this.productCatalog = []
        this.allProductsList.forEach(element => {
          this.productCatalog.push(element)
        })
        val.forEach(item => {
          if (item.product && item.product !== null) {
            choosedProducts.push(item.product)
            item.quantity = ((this.type === 'outbound' || this.$route.params.type === 'outbound') && (item.quantity > item.product.current_inventory_level)) ? item.product.current_inventory_level : item.quantity
          }
        })
        for (let i = 0; i < choosedProducts.length; i++) {
          this.productCatalog.forEach(element => {
            if (choosedProducts[i] && (element.id === choosedProducts[i].id)) {
              this.productCatalog.splice(this.productCatalog.indexOf(element), 1)
            }
          })
        }
      },
      deep: true
    },
    orderModal (val) {
      if (val) {
        this.loadGovernorates()
        this.loadProductCatalog()
        this.loadFinancialsInsurance()
        this.loadProductFlags()
        this.loadFulfillmentCenters()
      }
    },
    'searchVal' (val) {
      if (val === '') {
        this.loadProductCatalog()
      }
    },
    'order.customer_city.id' (val) {
      if (this.order.customer_city) {
        this.loadZones(val)
      }
    },
    orderProduct (val) {
      if (val && this.type === 'Edit Product') {
        this.products = []
        this.products.push(this.orderProduct)
        if (this.$store.state.AppActiveUser.userRole === 'merchant') {
          this.loadProductCatalog()
          this.loadGovernorates()
        }
      }
    },
    'serviceLevels' () {
      this.order.serviceLevel = this.serviceLevels[0]
    },
    'order.serviceLevel' (val) {
      if (this.serviceLevels) {
        this.order.serviceLevel = val
      }
    }
  },
  computed: {
    codDisplay: {
      get () {
        return this.order.cash_amount ? this.order.cash_amount.toFixed(2) : '0.00'
      },
      set (newValue) {
        this.order.cash_amount = Number(newValue)
      }
    },
    declaredValue: {
      get () {
        return this.order.declared_value ? this.order.declared_value.toFixed(2) : '0.00'
      },
      set (newDeclaredValue) {
        this.order.declared_value = Number(newDeclaredValue)
      }
    }
  },
  components: {
    ShipbluPrompt,
    vSelect,
    ShipbluVSelect
  },
  methods: {
    handleProductSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadProductsSearch, 1000)
    },
    loadProductsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      if (this.searchVal) {
        this.loadProductCatalog()
      }
    },
    loadGovernorates () {
      const govPromise = common.loadGovernorates(this)
      govPromise.then((results) => {
        this.governorates = results.data
      })
    },
    loadCities (governorateID) {
      const cityPromise = common.loadCities(governorateID, this)
      cityPromise.then((results) => {
        this.cities = results.data
      })
    },
    loadZones (cityID) {
      const zonePromise = common.loadZones(cityID, this)
      zonePromise.then((results) => {
        this.zones = results.data.filter(item => item.id !== 284)
      })
    },
    loadFinancialsInsurance () {
      sendRequest(false, false, this, `api/v1/merchants/${this.merchantID}/insurance-packages/`, 'get', null, true,
        (response) => {
          this.financialsInsurance = response.data
        }
      )
    },
    checkedItem (element, array) {
      array.forEach(item => {
        if (item.name === element.name) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
    },
    closeModal () {
      this.$emit('orderModal', false)
      this.$emit('loadOrder')
      this.$emit('loadOrders')
      this.searchVal = ''
      this.orderProduct = {}
      this.order = {}
      this.products = [
        {
          fulfillment_center: '',
          product: '',
          flags: [],
          quantity: 1
        }
      ]
      this.providers[0].checked = true
      this.providers[1].checked = false
      this.showAdditional = false
    },
    addProduct () {
      this.products.push({
        fulfillment_center: '',
        product: '',
        flags: [],
        quantity: 1
      })
    },
    removeProduct (index) {
      this.products.splice(index, 1)
    },
    loadProductCatalog () {
      this.searchInProgress = true
      sendFulfillmentRequest(false, false, this, `api/v1/fc/products/?merchant=${this.merchantID}&limit=100&search=${this.searchVal}`, 'get', null, true,
        (response) => {
          this.allProductsList = response.data.results
          this.allProductsList.map(item => {
            item.optionalLabel = `Name: ${item.name} . ${item.current_inventory_level} available`
          })
          this.productCatalog = []
          this.allProductsList.forEach(element => {
            this.productCatalog.push(element)
          })
          this.productCatalog = this.productCatalog.sort(function (product, secondProduct) { return secondProduct.current_inventory_level - product.current_inventory_level })
          for (let i = 0; i < this.orderProducts.length; i++) {
            this.productCatalog.forEach(element => {
              if (element.id === this.orderProducts[i].product.id) {
                this.productCatalog.splice(this.productCatalog.indexOf(element), 1)
              }
            })
          }
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    loadFulfillmentCenters () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/fulfillment-centers/', 'get', null, true,
        (response) => {
          this.fulfillmentCenters = response.data.results
        }
      )
    },
    loadProductFlags () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/flags/', 'get', null, true,
        (response) => {
          this.productFlags = response.data.results
        }
      )
    },
    addOrder () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const orderData = {
            customer: { ...this.order.customer },
            flags: this.order.flags.map(item => item.id),
            products: [],
            packages: [],
            merchant_reference: this.order.shipping_provider_metadata === 'shipblu' ? this.order.merchant_order_reference : this.order.merchant_reference,
            notes: this.order.shipping_provider_metadata === 'shipblu' ? this.order.order_notes : this.order.notes
          }
          orderData.customer.address.shipping_provider_metadata[this.order.shipping_provider_metadata] = this.order.shipping_provider_metadata
          let providerobj = {}
          
          if (this.order.shipping_provider_metadata === 'shipblu') {
            const customerZone = {
              id: this.order.customerZone.id
            }
            orderData.delivery = {
              packages: [
                {
                  package_size: 2
                }
              ],
              is_customer_allowed_to_open_packages: this.order.is_customer_allowed_to_open_packages,
              cash_amount: Number(this.codDisplay),
              service_level: this.order.serviceLevel.id
            }
            if (this.type === 'outbound') {
              orderData.delivery.declared_value = Number(this.declaredValue)
            }
            providerobj = {
              order: {
                zone: customerZone,
                what3words: this.order.customer.address.what3words
              }
            }
          } else {
            providerobj = {
              city: this.order.customer_city,
              governorate: this.order.customer_governorate
            }
          }
          orderData.customer.address.shipping_provider_metadata[this.order.shipping_provider_metadata] = providerobj
          this.products.forEach(element => {
            orderData.products.push({
              product: element.product.id,
              flags: element.flags.map(item => item.id),
              quantity: Number(element.quantity),
              fulfillment_center: element.fulfillment_center.id
            })
          })
          orderData.packages.push({
            package_size: 2,
            description: '',
            fragile: false
          })
          sendFulfillmentRequest(false, false, this, `api/v1/fc/orders/${this.type}/`, 'post', orderData, true,
            () => {
              this.closeModal()
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Order'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
            }
          )
        }
      }) 
    },
    addOrderProduct () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const products = []
          this.products.forEach(element => {
            products.push({
              order: this.$route.params.orderID,
              product: element.product.id,
              flags: element.flags.map(item => item.id),
              quantity: Number(element.quantity),
              fulfillment_center: element.fulfillment_center.id
            })
          })
          sendFulfillmentRequest(false, false, this, `api/v1/fc/orders/${this.$route.params.orderID}/products/`, 'post', products, true,
            () => {
              this.closeModal()
              this.$emit('loadOrder')
              this.$emit('orderModal', false)
            }
          )
        }
      })
    },
    updateProduct () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const product = {
            flags: this.products[0].flags.map(item => item.id),
            fulfillment_center: this.products[0].fulfillment_center.id,
            product: this.products[0].product.id,
            quantity: this.products[0].quantity
          }
          sendFulfillmentRequest(false, false, this, `api/v1/fc/orders/${this.$route.params.orderID}/products/${this.orderProduct.id}/`, 'patch', product, true,
            () => {
              this.closeModal()
              this.$emit('loadOrder')
              this.$emit('orderModal', false)
            }
          )
        }
      })
    },
    loadServicesAvailability (id) {
      sendRequest(false, false, this, `api/v1/services-availability/?governorate_id=${id}&subscription_id=${this.subscriptionId}`, 'get', null, true, 
        (response) => {
          const serviceLevels = response.data.map(element => element.service_level)
          const key = 'id'

          this.serviceLevels = [...new Map(serviceLevels.map(item => [item[key], item])).values()]
          this.serviceLevels = this.serviceLevels.reverse()
        }
      )
    }
  },
  created () {
    if (this.$store.state.AppActiveUser.userRole === 'merchant') {
      const merchantData = common.checkMerchantInLocalStorage(this)
      let user = {}
      merchantData.then(results => {
        user = results.merchantData
        this.merchantID = user.id
      })
    }
  }
}
</script>

<style lang="scss">
.text-darkgray {
  color: #475C6F;
}
.supply-table-cell-content {
  align-items: center;
  padding: 8px;
  background: #EBF5FF;
  border-radius: 4px;
}
.small-card {
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(108, 132, 163, 0.32);
  border-radius: 4px;
  text-align: center;
}
.product-dropdown .vs__dropdown-toggle .vs__actions svg {
  transform: scale(0.75);
  fill: rgba(0,0,0,.5);
}
</style>