<template>
  <shipblu-prompt
    class="shipment-modal"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="pickingModal"
    :title="$t('Picking Order')"
    :buttons-hidden="true" autocomplete="nofill">
    <div v-for="(item, index) in orderProducts" :key="index">
      <shipblu-card :cardLoading="cardLoading" class="p-4 mt-4">
        <div v-for="(storage, storageIndex) in item.storage_space" :key="storageIndex" class="relative items-end grid md:grid-cols-7 sm:grid-cols-5 grid-cols-1 md:gap-4 gap-2 py-4">
          <div class="sm:col-span-3 col-span-1">
            <vs-input disabled class="input-customer-info-filled w-full input-customer-info" 
            autocomplete="nofill" v-model="item.product.sku" :name="'sku'"/>
          </div>
          <div class="sm:col-span-2 col-span-1">
            <v-select class="input-customer-info-filled w-full input-customer-info product-dropdown" 
              autocomplete="nofill" label="ops_id" :name="`storage unit-${index}-${storageIndex}`" v-validate="'required'" 
              :placeholder="$t('storage unit') + '*'" v-model="storage.unit" :options="item.storage_list"/> 
            <span class="text-danger text-sm" v-show="errors.has(`storage unit-${index}-${storageIndex}`)">{{ errors.first(`storage unit-${index}-${storageIndex}`) ? errors.first(`storage unit-${index}-${storageIndex}`).split(`-${index}-${storageIndex}`)[0] + errors.first(`storage unit-${index}-${storageIndex}`).split(`-${index}-${storageIndex}`)[1] : '' }}</span>
          </div>
          <div class="sm:col-span-2 col-span-1 flex items-center w-full justify-between">
            <div class="md:block flex items-center">
              <p class="text-sm text-lightgray mr-1">{{ $t('Quantity') }}:</p>
              <div>
                <vs-input-number min="1" :max="item.quantity - item.storage_space.reduce((acc, curr) => acc + curr.quantity, 0) + storage.quantity" v-validate="'required|numeric'" :name="`quantity-${index}-${storageIndex}`" v-model.number="storage.quantity" class="stock-request-input-number my-0"/>
                <span class="text-danger text-sm" v-show="errors.has(`quantity-${index}-${storageIndex}`)">{{ errors.first(`quantity-${index}-${storageIndex}`) ? errors.first(`quantity-${index}-${storageIndex}`).split(`-${index}-${storageIndex}`)[0] + errors.first(`quantity-${index}-${storageIndex}`).split(`-${index}-${storageIndex}`)[1] : '' }}</span>
              </div>
            </div>
          </div>
          <span v-if="item.storage_space.length > 1" @click="removeStorageUnits(index, storageIndex)" class="material-symbols-outlined remove-package-icon text-sm cursor-pointer absolute top-0 right-0">close</span>
        </div>
        <div v-if="item.storage_space.reduce((acc, curr) => acc + curr.quantity, 0) < item.quantity" class="relative flex items-center justify-end mt-4">
          <div class="absolute add-products">
            <span class="text-sm font-medium py-1 px-2 rounded tooltip bg-white text-primary">{{$t('Add more Storage Units')}}</span>
          </div>
          <div class="p-1 flex self-center bg-primary rounded">
            <feather-icon @click="addStorage(index)" class="cursor-pointer" icon="PlusIcon" svgClasses="w-5 h-5 text-white" />
          </div>
        </div>
      </shipblu-card>
    </div>
    <div class="grid grid-cols-2 gap-4 mt-10">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button  class="btn active-btn" @click="sendPickingOrder()">{{ $t('Save') }}</button>
    </div>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import vSelect from 'vue-select'
import { sendFulfillmentRequest } from '../../../http/axios/requestHelper'
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'

export default {
  props: ['pickingModal', 'orderProducts', 'orderData'],
  data () {
    return {
      cardLoading: false
    }
  },
  methods: {
    sendPickingOrder () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const products = []
          this.orderProducts.forEach(item => {
            item.storage_space.forEach(element => {
              products.push({
                'product': item.product.id,
                'storage_unit': element.unit.id,
                'quantity': element.quantity
              })
            })
          })
          const sendData = {
            'order': this.$route.params.orderID,
            products
          }
          sendFulfillmentRequest(false, false, this, 'api/v2/fc/products-in-storage-unit/', 'post', sendData, true,
            () => {
              this.$emit('loadOrder')
              this.$emit('loadTrackings')
              this.$emit('pickingModal', false)
            }
          )
        }
      })
    },
    closeModal () {
      this.$emit('loadOrder')
      this.$emit('pickingModal', false)
    },
    addStorage (storageIndex) {
      this.orderProducts[storageIndex].storage_space.push({
        unit: '',
        quantity: this.orderProducts[storageIndex].quantity - this.orderProducts[storageIndex].storage_space.reduce((acc, curr) => acc + curr.quantity, 0)
      })
      this.cardLoading = true
      setTimeout(() => {
        this.cardLoading = false
      }, 0.5)
    },
    removeStorageUnits (index, storageIndex) {
      this.orderProducts[index].storage_space.splice(storageIndex, 1)
      this.cardLoading = true
      setTimeout(() => {
        this.cardLoading = false
      }, 0.5)
    }
  },
  components: {
    ShipbluPrompt,
    vSelect,
    ShipbluCard
  }
}
</script>

<style lang="scss">
.remove-package-icon {
  background: rgba(157, 173, 194, 0.32);
  border-radius: 3px;
  padding: 2px;
  &:hover {
    background: #9DADC2;
    color: #fff;
  }
}
</style>