<template>
  <div>
    <shipblu-card :cardLoading="productLoading" class="p-5">
      <div class="flex mb-3 justify-between">
        <h3>{{$t('Products')}}</h3>
        <vs-button v-if="$store.state.AppActiveUser.userRole === 'merchant' && orderData.status === 'created'" @click="addNewProduct('Add New Product')" color="primary" class="py-2 px-3">{{ $t('Add more products') }}</vs-button>
      </div>
      <shipblu-table
        :sst="true"
        multiple
        v-model="selected"
        :data="orderProducts"
      >
        <template slot="thead">
          <shipblu-th>{{$t('SKU')}}</shipblu-th>
          <shipblu-th>{{$t('Product Name')}}</shipblu-th>
          <shipblu-th>{{$t('Description')}}</shipblu-th>
          <shipblu-th>{{$t('Quantity')}}</shipblu-th>
          <shipblu-th>{{$t('Size')}}</shipblu-th>
          <shipblu-th>{{$t('Color')}}</shipblu-th>
          <shipblu-th>{{$t('Flags')}}</shipblu-th>
          <shipblu-th v-if="$store.state.AppActiveUser.userRole === 'merchant'">{{$t('Actions')}}</shipblu-th>
        </template>

        <template slot-scope="{ data }">
          <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <shipblu-td :data="data[indextr].sku">
              {{ data[indextr].product ? data[indextr].product.sku : '' }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].productName">
              {{ data[indextr].product ? data[indextr].product.name : '' }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].description">
              {{ data[indextr].product ? data[indextr].product.description : '' }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].description">
              {{ data[indextr].product ? data[indextr].quantity : '' }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].size">
              {{ data[indextr].product ? data[indextr].product.size : '' }}
            </shipblu-td>

            <shipblu-td :data="data[indextr].color">
              <div class="color-container h-6 w-3/4">
                <div class="w-full h-full" :style="`background-color:${ data[indextr].product ? data[indextr].product.color : 'black'}`"></div>
              </div>
            </shipblu-td>

            <shipblu-td :data="data[indextr].flags">
              <p v-for="(item, index) in data[indextr].flags" :key="index">
                {{ item.name }}
              </p>
            </shipblu-td>

            <shipblu-td v-if="$store.state.AppActiveUser.userRole === 'merchant'">
              <span @click="addNewProduct('Edit Product', data[indextr])" 
                :disabled="!orderInfoEditable" :class="orderData.status === 'created' ? 'text-primary' : 'disabled'">
                <feather-icon
                  icon="EditIcon"
                  svgClasses="h-6 w-6"
                  class="mr-2"
                />
              </span>
              <span v-if="orderProducts.length > 1" @click="$emit('deleteProduct', data[indextr])" 
                :disabled="!orderInfoEditable" :class="orderData.status === 'created' ? 'text-danger' : 'disabled'">
                <feather-icon
                  icon="Trash2Icon"
                  svgClasses="h-6 w-6"
                />
              </span>
            </shipblu-td>
          </shipblu-tr>
        </template>
      </shipblu-table>
    </shipblu-card>

    <add-order :orderProducts="orderProducts" :orderProduct="orderProduct" :type="type" :orderModal="orderModal" :order="order" @orderModal="orderModal = $event" @loadOrder="loadOrder"></add-order>
  </div>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import ShipbluTable from '@/layouts/components/ShipBluTable.vue'
import ShipbluTr from '@/layouts/components/ShipBluTr.vue'
import ShipbluTh from '@/layouts/components/ShipBluTh.vue'
import ShipbluTd from '@/layouts/components/ShipBluTd.vue'
import AddOrder from '../../components/AddOrder.vue'

export default {
  props: ['selected', 'orderProducts', 'orderData', 'orderInfoEditable', 'productLoading'],
  data () {
    return {
      type: 'Add New Product',
      orderProduct: {},
      order: {},
      orderModal: false
    }
  },
  methods: {
    addNewProduct (type, data) {
      this.type = type
      if (type === 'Add New Product') {
        this.stockProduct = Object.assign({}, {})
      } else {
        this.orderProduct = data
        this.orderProduct.product.optionalLabel = `SKU: ${data.product.sku} . ${data.product.current_inventory_level} available`
      }
      this.orderModal = true
    },
    loadOrder () {
      this.$emit('loadOrder')
    }
  },
  components: {
    ShipbluCard,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    AddOrder
  }
}
</script>