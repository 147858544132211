<template>
  <div>
    <!-- buttons-->
    <div class="flex pb-4">
      <template v-if="$store.state.AppActiveUser.userRole === 'fc-operations-supervisor' && orderData.status === 'confirmed_to_ship'">
        <div v-if="orderPackages.length > 0 && orderPackages.length <= 4 && $route.params.type !== 'inbound' && orderData.status === 'confirmed_to_ship'" class="px-4 py-2 status-btn mr-2 flex items-center cursor-pointer" @click="pickingModal = true">
          <span class="text-sm">{{ $t('Ready To Ship') }}</span>
        </div>
        <template v-else>
          <vx-tooltip position="bottom" color="warning" :text="`${$t('Add one package at least and maximum four packages.')}`">
            <div class="cursor-not-allowed">
              <div class="pointer-events-none unavailable-action flex px-4 py-2 mr-2">
                <span class="text-sm ml-2">{{ $t('Ready To Ship') }}</span>
              </div>
            </div>
          </vx-tooltip>
        </template>
      </template>
      <div v-if="$store.state.AppActiveUser.userRole === 'fc-operations-supervisor' && orderData.status === 'received' && $route.params.type === 'inbound'" class="px-4 py-2 status-btn mr-2 flex items-center cursor-pointer" @click="pickingModal = true">
        <span class="text-sm">{{ $t('Complete') }}</span>
      </div>
      <div class="cursor-pointer vs-con-loading__container relative" v-if="orderData.linked_shipping_order !== null"  id="print-btn-loading">
        <div class="px-4 py-2 mr-2 flex items-center cursor-pointer available-action"  @click="printLabel()">
          <feather-icon icon="PrinterIcon" svgClasses="w-4 h-4"/>
          <span class="text-sm ml-2">{{ $t('Print AWB') }}</span>
        </div>
      </div>
      <div v-else class="cursor-not-allowed">
        <div class="pointer-events-none unavailable-action flex px-4 py-2">
          <feather-icon icon="PrinterIcon" svgClasses="w-4 h-4" class="self-center"/>
          <span class="text-sm ml-2">{{ $t('Print AWB') }}</span>
        </div>
      </div>
    </div>
    <!-- Products card -->
    <products :productLoading="productLoading" :selected="selected" :orderProducts="orderProducts" :orderData="orderData" :orderInfoEditable="orderInfoEditable" @deleteProduct="deleteProduct" @loadOrder="loadOrder"></products>
    
    <div class="grid grid-cols-3 gap-4 mt-4">
      <div class="lg:col-span-2 col-span-3">
        <!-- Packaging -->
        <packaging :packageLoading="packageLoading" :selected="selected" :orderPackages="orderPackages" :orderData="orderData" @loadOrder="loadOrder" :orderInfoEditable="orderInfoEditable" @deleteProduct="deleteProduct"></packaging>

        <!-- Shipping Info -->
        <shipblu-card :cardLoading="shippingLoading" class="p-5 mt-4">
          <div class="flex justify-between items-center mt-6">
            <h3 class="">{{$t('Shipping Info')}}</h3>
            <div class="flex items-center justify-center sm:mt-0">
              <div v-for="(providerItem, index) in providers" :key="index">
                <label v-if="orderData.customer.address.shipping_provider_metadata && orderData.customer.address.shipping_provider_metadata.hasOwnProperty(providerItem.name)" @click="checkedItem(providerItem, providers)" class="prompt cursor-pointer" :class="index === 0 ? 'mr-4' : ''">
                  <input disabled v-model="provider" :class="provider === providerItem.name ? 'checked' : ''" :value="providerItem.name" type="radio" />
                  <span class="py-2 px-4 rounded capitalize text-sm">{{ $t(providerItem.name.replace('b', 'B')) }}</span>
                </label>
              </div> 
            </div>
          </div>
          <div class="items-end grid sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-2">
            <div class="col-span-1">
              <vs-input :disabled="!orderInfoEditable" :class="orderData.customer.full_name ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" v-validate="'required'" :label-placeholder="$t('Full Name') + '*'" name="full name" v-model="orderData.customer.full_name"/>
              <span class="text-danger text-sm" v-show="errors.has('full name')">{{ errors.first('full name') }}</span>
            </div>
            <div class="col-span-1">
              <vs-input :disabled="!orderInfoEditable" :class="orderData.customer.phone ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" v-validate="{ required: true, regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }" :label-placeholder="$t('Phone Number') + '*'" name="phone number" v-model="orderData.customer.phone"/>
              <span class="text-danger text-sm" v-show="errors.has('phone number')">{{ errors.first('phone number') }}</span>
            </div>
          </div>
          <div class="grid grid-cols-1 sm:gap-4 gap-2">
            <div class="col-span-1">
              <vs-input name="additional phone number" v-validate="{ regex: /(^0[0-9]\d{8,15}$|^\u0660\u0661[\u0660-\u0669][\u0660-\u0669]{8,15}$)/ }" :disabled="!orderInfoEditable" :class="orderData.customer.secondary_phone ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" :label-placeholder="$t('Additional Phone Number')" v-model="orderData.customer.secondary_phone"/>
              <span class="text-danger text-sm" v-show="errors.has('additional phone number')">{{ errors.first('additional phone number') }}</span>
            </div>
          </div>
          <div class="grid grid-cols-1">
            <div class="col-span-1">
              <vs-input :disabled="!orderInfoEditable" :class="orderData.customer.address.line_1 ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" v-validate="'required'" name="address 1" :label-placeholder="$t('Customer Address 1') + '*'" v-model="orderData.customer.address.line_1"/>
              <span class="text-danger text-sm" v-show="errors.has('address 1')">{{ errors.first('address 1') }}</span>
            </div>
            <div class="col-span-1">
              <vs-input :disabled="!orderInfoEditable" :class="orderData.customer.address.line_2 ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" v-validate="'required'" name="address 2" :label-placeholder="$t('Customer Address 2') + '*'" v-model="orderData.customer.address.line_2"/>
              <span class="text-danger text-sm" v-show="errors.has('address 2')">{{ errors.first('address 2') }}</span>
            </div>
          </div>
          <template>
            <div class="grid grid-cols-1 gap-4 mt-4" v-show="provider === 'shipblu'">
              <div class="col-span-1">
                <v-select autocomplete="nofill" :disabled="!orderInfoEditable" :options="governorates" :class="governorate ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" name="governorate" label="name" item-text="name"
                  v-model="governorate" item-value="id" return-object v-on:input="governorate ? loadCities(`${governorate.id}`) : zones = [], zone = '', cities = [], city = ''"
                  :placeholder="$t('Governorate')" v-validate="'required'"></v-select>
                <span class="text-danger text-sm" v-show="errors.has('governorate')">{{ errors.first('governorate') }}</span>
              </div>
              <div class="col-span-1">
                <v-select autocomplete="nofill" :disabled="!orderInfoEditable" :options="cities" :class="city ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" name="city" label="name" item-text="name"
                  v-model="city" item-value="id" return-object v-on:input="city ? loadZones(`${city.id}`) : zones = [], zone = ''"
                  :placeholder="$t('City')" v-validate="'required'"></v-select>
                <span class="text-danger text-sm" v-show="errors.has('city')">{{ errors.first('city') }}</span>
              </div>
              <div class="col-span-1">
                <v-select autocomplete="nofill" :disabled="!orderInfoEditable" :options="zones" :class="zone ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" name="zone" label="name" item-text="name"
                  v-model="zone" item-value="id" return-object :placeholder="$t('Zone')" v-validate="'required'"></v-select>
                <span class="text-danger text-sm" v-show="errors.has('zone')">{{ errors.first('zone') }}</span>
              </div>
              <div class="sm:flex items-center justify-between">
                <div class="flex items-center sm:justify-start justify-center">
                  <img src="@/assets/images/logo/w3w.svg" alt="">
                  <p class="leading-tight text-darkgray font-medium mx-1 text-xs text-lightgray">{{ $t('(Optional)') }}</p>
                  <a target="blank" href="https://what3words.com/tricycle.hotel.jeering" class="material-icons-outlined text-primary text-2xl cursor-pointer">help_center</a>
                </div>
                <div class="sm:m-0 m-auto sm:mt-0 mt-3">
                  <what3words-autosuggest clip_to_country="EG" :api_key="W3W_API_KEY">
                    <input :disabled="!orderInfoEditable" class="w-68 input-on" v-model="orderData.customer.address.what3words" name="w3w" id="what3words"/>
                  </what3words-autosuggest>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-1 mt-4" v-if="provider === 'other'">
              <div class="col-span-1">
                <vs-input :disabled="!orderInfoEditable" :class="governorate ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" v-validate="'required'" :label-placeholder="$t('Governorate') + '*'" name="governorate" v-model="governorate"/>
                <span class="text-danger text-sm" v-show="errors.has('governorate')">{{ errors.first('governorate') }}</span>
              </div>
              <div class="col-span-1">
                <vs-input :disabled="!orderInfoEditable" :class="city ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" v-validate="'required'" :label-placeholder="$t('City') + '*'" name="city" v-model="city"/>
                <span class="text-danger text-sm" v-show="errors.has('city')">{{ errors.first('city') }}</span>
              </div>
            </div>
          </template>
          <div class="grid grid-cols-2 sm:gap-4 gap-2">
            <div class="col-span-1">
              <vs-input :disabled="!orderInfoEditable" :class="orderData.merchant_reference ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" name="merchant reference" :label-placeholder="$t('Merchant Reference') + '*'" v-model="orderData.merchant_reference"/>
            </div>
            <div class="col-span-1 self-end">
              <v-select :disabled="$store.state.AppActiveUser.userRole === 'fc-operations-supervisor' || !orderInfoEditable" :class="orderData.flags && orderData.flags.length !== 0 ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" 
                multiple autocomplete="nofill" label="name" :name="`flags`" v-model="orderData.flags" :placeholder="$t('Flags')" :options="productFlags"/>
            </div>
          </div>
          <div class="grid grid-cols-1 mt-2">
            <div class="col-span-1">
              <vs-input :disabled="!orderInfoEditable" :class="orderData.notes ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" name="notes" :label-placeholder="$t('Notes') + '*'" v-model="orderData.notes"/>
            </div>
          </div>
          <div class="flex justify-end gap-4 mt-6" v-if="$store.state.AppActiveUser.userRole === 'merchant'">
            <button v-if="!orderInfoEditable && (orderData.status === 'created' || orderData.status === 'confirmed_to_ship')" @click="enableEdit()" class="active-btn btn px-6">{{ $t('Edit') }}</button>
            <button v-if="orderInfoEditable" @click="closeEdit" class="btn disable-btn px-6">{{ $t('Cancel') }}</button>
            <button v-if="orderInfoEditable" @click="updateOrder" class="active-btn btn px-6">{{ $t('Save') }}</button>
          </div>
        </shipblu-card>
      </div>

      <!-- Tracking -->
      <div class="lg:col-span-1 col-span-3">
        <order-tracking :trackingLoading="trackingLoading" @trackingLoading="trackingLoading = $event" :trackings="trackings" :orderData="orderData" @loadOrder="loadOrder" @loadTrackings="loadTrackings"/>
      </div>
    </div>
    <pickingOrder :orderData="orderData" :pickingModal="pickingModal" @pickingModal="pickingModal = $event" @loadOrder="loadOrder" @loadTrackings="loadTrackings" :orderProducts="orderProducts"/>
  </div>
</template>

<script>
import {sendFulfillmentRequest} from '../../../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import i18nData from '../../../../i18n/i18nData.js'
import vSelect from 'vue-select'
import OrderTracking from '../components/OrderTracking.vue'
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import products from './components/Products.vue'
import packaging from './components/Packaging.vue'
import axios from 'axios'
import pickingOrder from '../../../fcOperationsSupervisor/components/pickingOrder.vue'


export default {
  data () {
    return {
      W3W_API_KEY: process.env.VUE_APP_WHAT3WORDS_API_KEY,
      selected: [],
      orderInfoEditable: false,
      trackings: [],
      orderProducts: [],
      productFlags: [],
      fulfillmentCenters: [],
      deleteData: {},
      orderData: {
        customer: {
          address: {
            shipping_provider_metadata: {
              other: {
                governorate: '',
                city: ''
              }
            }
          }
        }
      },
      providers: [
        {
          name: 'shipblu',
          checked: true
        },
        {
          name: 'other',
          checked: false
        }
      ],
      city: '',
      zone: {},
      governorate: '',
      zones: [],
      cities: [],
      governorates: [],
      orderPackages: [],
      provider: '',
      productLoading: false,
      packageLoading: false,
      shippingLoading: false,
      trackingLoading: false,
      ready: false,
      clicks: 0,
      pickingModal: false
    }
  },
  components: {
    vSelect,
    OrderTracking,
    ShipbluCard,
    products,
    packaging,
    pickingOrder
  },
  watch: {
    provider () {
      if (this.orderInfoEditable) {
        this.city = ''
        this.governorate = ''
      }
    }
  },
  methods: {
    loadStorageUnits (unit) {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/storage-units/?product_sku=${unit.product.sku}`, 'get', null, true,
        (response) => {
          const storageList = response.data.results
          this.orderProducts.filter(item => item.id === unit.id)[0].storage_list = storageList
        }
      )
    },
    enableEdit () {
      this.loadGovernorates()
      this.loadProductFlags()
      this.orderInfoEditable = true
    },
    printLabel () {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#print-btn-loading',
        scale: 0.45
      })
      axios({
        url: `${process.env.VUE_APP_API_URL}/api/v1/orders/shipping-label/?type=pdf&tracking_numbers=${this.orderData.linked_shipping_order.tracking_number}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Outbound-Order.pdf')
        document.body.appendChild(link)
        link.click()
        this.$vs.loading.close('#print-btn-loading > .con-vs-loading')
      }).catch((error) => {
        if (error && error.response && error.response.status === 400 && error.response.status) {
          this.$vs.notify({
            color:'danger',
            title: i18nData[this.$i18n.locale]['Failed'],
            text: i18nData[this.$i18n.locale]['Cannot generate a shipping label for unzoned orders.'],
            position: 'top-center'
          })
        }
        this.$vs.loading.close('#print-btn-loading > .con-vs-loading')
      })
    },
    checkedItem (element, array) {
      array.forEach(item => {
        if (item.name === element.name) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
    },
    addTracking (status) {
      const tracking = {
        order: Number(this.$route.params.orderID),
        status: status ? status : this.status.value,
        extra_info: this.failureReason
      }
      this.trackingLoading = true
      sendFulfillmentRequest(true, false, this, `api/v1/fc/orders/${this.$route.params.orderID}/tracking/`, 'post', tracking, true,
        () => {
          if (this.clicks === 2) {
            this.$vs.notify({
              color:'success',
              title:i18nData[this.$i18n.locale]['Success'],
              text:i18nData[this.$i18n.locale]['Stock request'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
              position: 'top-center'
            })
            this.loadTrackings()
            this.clicks = 0
          } else if (this.clicks === 1) {
            this.addTracking('ready_to_ship')
          } else {
            this.addTracking('packed')
          }
          this.clicks++
          this.loadOrder()
        }, (error) => {
          common.notifyResponseError(this, error)
          this.trackingLoading = false
        }
      )
    },
    updateOrder () {
      this.$validator.validateAll().then(result => {
        if (result) {
          let orderInfoData = {}
          let providerobj = {}
          const customerZone = {
            id: this.zone.id,
            name: this.zone.name
          }
          const customerCity = {
            id: this.city.id,
            name: this.city.name
          }
          const customerGovernorate = {
            id: this.governorate.id,
            name: this.governorate.name
          }
          if (this.provider === 'shipblu') {
            orderInfoData.delivery = {}
            providerobj = {
              order: {
                id: this.orderData.customer.address.shipping_provider_metadata.shipblu.order.id,
                tracking_number: this.orderData.customer.address.shipping_provider_metadata.shipblu.order.tracking_number,
                zone: customerZone,
                city: customerCity,
                governorate: customerGovernorate,
                what3words: this.orderData.customer.address.what3words
              }
            }
          } else {
            providerobj = {
              city: this.city,
              governorate: this.governorate
            }
          }
          orderInfoData = {
            ...orderInfoData,
            flags: this.orderData.flags.map(item => item.id),
            merchant_reference: this.orderData.merchant_reference,
            notes: this.orderData.notes,
            customer: {
              full_name: this.orderData.customer.full_name,
              phone: this.orderData.customer.phone,
              secondary_phone: this.orderData.customer.secondary_phone,
              email: this.orderData.customer.email,
              address: {
                line_1: this.orderData.customer.address.line_1,
                line_2: this.orderData.customer.address.line_2,
                shipping_provider_metadata: {}
              }
            }
          }
          orderInfoData.customer.address.shipping_provider_metadata[this.provider] = this.provider
          orderInfoData.customer.address.shipping_provider_metadata[this.provider] = providerobj
          this.shippingLoading = true
          sendFulfillmentRequest(true, false, this, `api/v1/fc/orders/${this.$route.params.type}/${this.$route.params.orderID}/`, 'patch', orderInfoData, true,
            () => {
              this.closeEdit()
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Order'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
            }
          )
        }
      })
    },
    closeEdit () {
      this.orderInfoEditable = false
      this.loadOrder()
    },
    deleteProduct (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: i18nData[this.$i18n.locale]['Confirm'],
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      this.deleteData.package_size ? this.packageLoading = true : this.productLoading = true
      sendFulfillmentRequest(true, false, this, `api/v1/fc/orders/${this.$route.params.orderID}/${this.deleteData.package_size ? 'packages' : 'products'}/${this.deleteData.id}/`, 'delete', null, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Item'] + i18nData[this.$i18n.locale][' has been deleted successfully!'],
            position: 'top-center'
          })
          this.loadOrder()
        }
      )
    },
    loadOrder () {
      this.productLoading = true
      this.packageLoading = true
      this.shippingLoading = true
      sendFulfillmentRequest(true, false, this, `api/v1/fc/orders/${this.$route.params.type}/${this.$route.params.orderID}/`, 'get', null, true,
        (response) => {
          this.orderData = response.data
          this.orderProducts = response.data.products
          this.orderProducts.forEach(item => {
            item.storage_space = [
              {
                unit: '',
                quantity: item.quantity
              }
            ]
          })
          this.orderPackages = response.data.packages
          this.productLoading = false
          this.packageLoading = false
          this.shippingLoading = false
          this.provider = this.orderData.customer.address.shipping_provider_metadata.shipblu ? 'shipblu' : 'other'
          if (this.orderData.customer.address.shipping_provider_metadata) {
            this.city = this.orderData.customer.address.shipping_provider_metadata.shipblu ? this.orderData.customer.address.shipping_provider_metadata.shipblu.order.city : this.orderData.customer.address.shipping_provider_metadata.other.city
            this.governorate = this.orderData.customer.address.shipping_provider_metadata.shipblu ? this.orderData.customer.address.shipping_provider_metadata.shipblu.order.governorate : this.orderData.customer.address.shipping_provider_metadata.other.governorate
            this.zone = this.orderData.customer.address.shipping_provider_metadata.shipblu ? this.orderData.customer.address.shipping_provider_metadata.shipblu.order.zone : {}
          }
          if (this.$store.state.AppActiveUser.userRole === 'fc-operations-supervisor') this.orderProducts.forEach((item) => this.loadStorageUnits(item))
        }
      )
    },
    loadProductFlags () {
      sendFulfillmentRequest(true, false, this, 'api/v1/fc/flags/', 'get', null, true,
        (response) => {
          this.productFlags = response.data.results
        }
      )
    },
    loadFulfillmentCenters () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/fulfillment-centers/', 'get', null, true,
        (response) => {
          this.fulfillmentCenters = response.data.results
        }
      )
    },
    loadTrackings () {
      this.trackingLoading = true
      sendFulfillmentRequest(true, false, this, `api/v1/fc/orders/${this.$route.params.orderID}/tracking/`, 'get', null, true,
        (response) => {
          this.trackings = response.data.results
          this.trackingLoading = false
        }, (error) => {
          this.trackingLoading = false
          common.notifyResponseError(this, error)
        }
      )
    },
    formatTime (time) {
      let hr = time.split(':')[0]
      const min = time.split(':')[1]
      const ampm = hr >= 12 ? i18nData[this.$i18n.locale]['pm'] : i18nData[this.$i18n.locale]['am']
      hr = hr > 12 ? hr -= 12 : hr
      return `${hr  }:${  min  }${ampm}`
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    loadGovernorates () {
      const govPromise = common.loadGovernorates(this)
      govPromise.then((results) => {
        this.governorates = results.data
        if (this.city.id) {
          this.loadCities(this.governorate.id)
        }
      })
    },
    loadCities (governorateID) {
      const cityPromise = common.loadCities(governorateID, this)
      cityPromise.then((results) => {
        this.cities = results.data
        if (this.city) {
          this.loadZones(this.city.id)
        }
      })
    },
    loadZones (cityID) {
      const zonePromise = common.loadZones(cityID, this)
      zonePromise.then((results) => {
        this.zones = results.data.filter(item => item.id !== 284)
      })
    }
  },
  created () {
    this.loadOrder()
    this.loadTrackings()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
.disabled {
  color: #C5CBD0;
  pointer-events: none;
}
.vx-timeline {
  margin-left: 0.5rem;
  padding-left: 40px;
  border-left: 2px dotted #e5e8eb;
  li {
    position: relative;
    .timeline-icon {
      position: absolute;
      top: 0;
      left: -3.2rem !important;
      border-radius: 50%;
      padding: 0px;
      padding: 0.3rem !important;
      padding-bottom: 0.4rem;
    }
  }
}
.product-order-status, .activity-e-status {
  margin-left: -30px;
}
.color-container {
  border: 1px solid #000;
  border-radius: 7px;
  padding: 2px;
  margin: auto !important;
  div {
    border-radius: 5px;
  }
}
</style>