<template>
  <shipblu-prompt
    class="order-modal stock-request"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="packageModal"
    :title="$t(type) + ' ' + $t('Packages')"
    :buttons-hidden="true" autocomplete="nofill">    
    <div v-if="type === 'Add'">
      <div v-if="packageSizes">
        <div class="grid md:grid-cols-4 grid-cols-2 gap-4 items-end mt-3">
          <p class="text-lg col-span-4"> {{ $t('Boxes') }} </p>
          <div v-for="(item, index) in packageSizes.filter(item => item.type === 'box')" :key="index">
            <div class="supply-table-cell-content flex items-center justify-between col-span-1" v-if="item.short_code != 'OSZ'">
              <div>
                <p class="leading-tight text-lightgray whitespace-nowrap ml-1">{{ item.name.split('(')[0] }}</p>
                <p class="text-lightgray leading-tight whitespace-nowrap text-xs ml-1">{{item.name.split('(')[1] ? item.name.split('(')[1].slice(0, -1) : ''}}</p>
              </div>
              <div class="p-1 flex self-center bg-primary rounded">
                <feather-icon @click="addPackage(item)" class="cursor-pointer" icon="PlusIcon" svgClasses="w-4 h-4 text-white"/>
              </div>
            </div>
          </div>
        </div>
        <p class="text-danger sm:text-left text-center text-sm mt-2" v-if="packageSizes.length > 0 && packages.length === 0 && clicks > 0"> {{ $t('This field is required') }}</p>
      </div>
      <div v-if="packageSizes">
        <div class="grid md:grid-cols-4 grid-cols-2 gap-4 items-end mt-3">
          <p class="text-lg col-span-4"> {{ $t('Flyers') }} </p>  
          <div v-for="(item, index) in packageSizes.filter(item => item.type === 'flyer')" :key="index">
            <div class="supply-table-cell-content flex items-center justify-between col-span-1" v-if="item.short_code != 'OSZ'">
              <div>
                <p class="leading-tight text-lightgray whitespace-nowrap ml-1">{{ item.name.split('(')[0] }}</p>
                <p class="text-lightgray leading-tight whitespace-nowrap text-xs ml-1">{{item.name.split('(')[1] ? item.name.split('(')[1].slice(0, -1) : ''}}</p>
              </div>
              <div class="p-1 flex self-center bg-primary rounded">
                <feather-icon @click="addPackage(item)" class="cursor-pointer" icon="PlusIcon" svgClasses="w-4 h-4 text-white"/>
              </div>
            </div>
          </div>
        </div>
        <p class="text-danger sm:text-left text-center text-sm mt-2" v-if="packageSizes.length > 0 && packages.length === 0 && clicks > 0"> {{ $t('This field is required') }}</p>
      </div>
      <div v-if="packages" class="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-2 gap-3 items-end w-full mt-4">
        <div v-for="(item, index) in packages" :key="index">
          <div class="flex items-center mt-4">
            <div class="small-card py-2 px-3 flex items-center">
              <span class="leading-tight whitespace-nowrap font-medium text-primary ml-1">{{ item.package_size.name }}</span>
              <div class="remove-package-icon ml-3 cursor-pointer" @click="removePackage(index)">
                <feather-icon icon="XIcon" svgClasses="w-3 h-3"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-10">
        <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
        <button @click="addOrderPackage" class="active-btn btn">{{ $t('Add') }}</button>
      </div>
    </div>
    <div v-else>
      <v-select class="w-full input-customer-info" 
        autocomplete="nofill" label="name" name="package size" v-model="packageObj.package_size" v-validate="'required'" 
        :placeholder="$t('Package Size') + '*'" :options="packageSizes"/>
      <span class="text-danger text-sm" v-show="errors.has('package size')">{{ errors.first('package size') }}</span>
      <div class="grid grid-cols-2 gap-4 mt-10">
        <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
        <button @click="updatePackage" class="active-btn btn">{{ $t('Save') }}</button>
      </div>
    </div>
  </shipblu-prompt>
</template>

<script>
import { sendFulfillmentRequest } from '../../../http/axios/requestHelper'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import vSelect from 'vue-select'

export default {
  props: ['packageModal', 'type', 'packageObj', 'orderPackages'],
  data () {
    return {
      packageSizes: [],
      packages: [],
      clicks: 0
    }
  },
  methods: {
    loadPackages () {
      sendFulfillmentRequest(true, false, this, 'api/v1/fc/package-sizes/', 'get', null, true,
        (response) => {
          this.packageSizes = response.data
        })
    },
    removePackage (index) {
      this.packages.splice(index, 1)
    },
    addPackage (item) {
      if ((this.orderPackages.length + this.packages.length) < 4)  {
        this.packages.push({
          package_size: item,
          description: '',
          fragile: false
        })
      }
    },
    closeModal () {
      this.packages = []
      this.$emit('packageModal', false)
      this.$emit('loadOrder')
    },
    changeSize (shortCode) {
      switch (shortCode) {
      case '(S)':
        return 'width: 20px;height: 25px'
      case '(M)':
        return 'width: 25px; height: 30px;'
      case '(L)':
        return 'width: 30px; height: 35px;'
      case '(XL)':
        return 'width: 35px; height: 40px;'
      }
    },
    addOrderPackage () {
      const packages = []
      this.packages.forEach(item => {
        packages.push({
          order: this.$route.params.orderID,
          package_size: item.package_size.id
        })
      })
      sendFulfillmentRequest(false, false, this, `api/v1/fc/orders/${this.$route.params.orderID}/packages/`, 'post', packages, true,
        () => {
          this.closeModal()
        })
    },
    updatePackage () {
      const packages = {
        order: this.$route.params.orderID,
        package_size: this.packageObj.package_size.id
      }
      sendFulfillmentRequest(false, false, this, `api/v1/fc/orders/${this.$route.params.orderID}/packages/${this.packageObj.id}/`, 'patch', packages, true,
        () => {
          this.closeModal()
        })
    }
  },
  created () {
    this.loadPackages()
  },
  components: {
    vSelect,
    ShipbluPrompt
  }
}
</script>