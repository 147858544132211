<template>
  <div>
    <shipblu-card class="mb-4 p-5" v-if="$store.state.AppActiveUser.userRole !== 'merchant'">
      <h3 class="mb-4">{{$t('Add Tracking Event')}}</h3>
      <form>
        <v-select class="w-1/2" name="tracking" v-model="status" :placeholder="$t('Tracking Event')" v-validate="'required'" label="name" :options="$route.params.type === 'inbound' ? inboundTrackingEvents : outboundTrackingEvents"/>
        <span class="text-danger text-sm" v-show="errors.has('tracking')">{{ errors.first('tracking') }}</span>
        <vs-input v-if="(status && status.value === 'failed_to_fulfill') || (status && status.value === 'failed_to_receive')" :class="failureReason? 'input-customer-info-filled' : ''" class="w-full input-customer-info" v-validate="'required'" name="failure reason" :label-placeholder="$t('Failure Reason') + '*'" v-model="failureReason"/>
        <span class="text-danger text-sm" v-show="errors.has('failure reason')">{{ errors.first('failure reason') }}</span>
      </form>
      <div class="flex flex-wrap items-center justify-end mt-6">
        <vs-button type="filled" color="success" @click="addTracking()" class="float-right block">{{$t('Add')}}</vs-button>
      </div>
    </shipblu-card>
    <shipblu-card :cardLoading="trackingLoading" class="p-5">
      <div class="flex justify-between mb-4">
        <h3 class="">{{$t('Tracking')}}</h3>
        <vs-button class="py-2 px-3" v-if="$store.state.AppActiveUser.userRole === 'merchant' && orderData.status === 'created' && $route.params.type === 'outbound'" @click="addTracking('confirmed_to_ship')">{{$t('Confirm to Ship')}}</vs-button>
      </div>
      <div v-for="(item, index) in trackings" :key="index">
        <ul class="vx-timeline h-20 w-full mb-0 xs:mb-32 sm:mb-0">
          <li>
            <div class="timeline-icon h-2 w-2 inline-block rounded-full mr-2 bg-grey"></div>
            <div class="vx-row flex">
              <div class="timeline-info vx-col w-1/3 sm:w-1/3 xs:w-full xs:mb-1">
                <small class="activity-e-status block">{{$t("Status")}}</small>
                <vs-chip
                  :color="getOrderStatusColor(item)"
                  class="break-all product-order-status">
                    {{ $t(getStatusLabel(item)) }}
                </vs-chip>
              </div>
              <div class="p-0 vx-col xl:pl-0 lg:w-1/3 lg:pr-2 sm:w-1/3 sm:text-left xs:text-left xs:w-full xs:mb-1">
                <small>{{$t("By")}}</small>
                <p class="font-medium lg:text-sm mr-2">{{item.by ? item.by : '' }}</p>
              </div>
              <div class="p-0 vx-col xl:w-1/3 lg:w-1/4 sm:w-1/3 sm:text-left xs:text-left xs:w-full xs:mb-1">
                <small>{{$t("Time")}}</small>
                <p class="font-medium lg:text-sm">{{ new Date(item.created).toLocaleDateString('fr-CA') }} {{ new Date(item.created).toLocaleTimeString('en-GB') }}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </shipblu-card>
  </div>
</template>

<script>
import common from '../../../../assets/utils/common'
import { sendFulfillmentRequest } from '../../../../http/axios/requestHelper'
import i18nData from '../../../../i18n/i18nData'
import vSelect from 'vue-select'
import ShipbluCard from '../../../../layouts/components/ShipbluCard.vue'

export default {
  props: ['trackings', 'orderData', 'trackingLoading'],
  data () {
    return {
      status: '',
      statusLoading: false,
      outboundTrackingEvents: [
        {
          name: 'Picked',
          value: 'picked'
        },
        {
          name: 'Packed',
          value: 'packed'
        },
        {
          name: 'Ready to Ship',
          value: 'ready_to_ship'
        },
        {
          name: 'Shipped',
          value: 'shipped'
        },
        {
          name: 'Cancelled',
          value: 'cancelled'
        },
        {
          name: 'Failed to Fulfill',
          value: 'failed_to_fulfill'
        }
      ],
      inboundTrackingEvents: [
        {
          name: 'Confirmed to Return',
          value: 'confirmed_to_return'
        },
        {
          name: 'Received',
          value: 'received'
        },
        {
          name: 'Completed',
          value: 'completed'
        },
        {
          name: 'Cancelled',
          value: 'cancelled'
        },
        {
          name: 'Failed to Receive',
          value: 'failed_to_receive'
        }
      ],
      failureReason: ''
    }
  },
  watch: {
    status () {
      this.failureReason = ''
    }
  },
  methods: {
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    addTracking (status) {
      this.$validator.validateAll().then(result => {
        if (result) {
          const tracking = {
            order: Number(this.$route.params.orderID),
            status: status ? status : this.status.value,
            extra_info: this.failureReason
          }
          this.trackingLoading = true
          this.$emit('trackingLoading', true)
          sendFulfillmentRequest(true, false, this, `api/v1/fc/orders/${this.$route.params.orderID}/tracking/`, 'post', tracking, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Stock request'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
              this.status = ''
              this.$emit('loadOrder')
              this.$emit('loadTrackings')
              this.statusLoading = false
            }, (error) => {
              common.notifyResponseError(this, error)
              this.statusLoading = false
              this.trackingLoading = false
              this.$emit('trackingLoading', false)
            }
          )
        }
      })
    }
  },
  components: {
    vSelect,
    ShipbluCard
  }
}
</script>